import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    { path: '/', component: () => import('../components/Home.vue') },
    { path: '/templates/default', name: 'default_templates', component: () => import('../components/DefaultTemplates.vue') },
    { path: '/ruleset/:rulesetId/settings', name: 'RulesetSettings', component: () => import('../components/RulesetSettings.vue') }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
