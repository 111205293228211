<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div
      id="navbarSupportedContent"
      class="collapse navbar-collapse"
    >
      <ul class="navbar-nav mr-auto">
        <li>
          <router-link
            to="/"
            active-class="nav-active"
            class="nav-item"
          >
            <a class="nav-link">Templates</a>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>
