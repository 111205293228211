/**
 * Since this app is loaded in an iframe, we get our credentials through the get-parameters
 *
 * @returns {{Authorization: string, client: string, "Content-Type": string}|{}}
 */
function authHeader() {
  try {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const credentials = {
      jwt: url.searchParams.get('jwt'),
      clientId: url.searchParams.get('client_id'),
      serviceClientId: url.searchParams.get('service_client_id')
    };

    if (credentials.jwt && credentials.clientId && credentials.serviceClientId) {
      return {
        "Authorization": 'Bearer ' + credentials.jwt,
        "Content-Type": "application/json",
        "X-Client": credentials.serviceClientId
      };
    }

  } catch (e) {
    console.error(e.message)
  }

  return {};
}

function authParams() {
  try {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const credentials = {
      jwt: url.searchParams.get('jwt'),
      clientId: url.searchParams.get('client_id'),
      serviceClientId: url.searchParams.get('service_client_id')
    };

    if (credentials.jwt && credentials.clientId && credentials.serviceClientId) {
      return `?client_name=${credentials.serviceClientId}&jwt=${credentials.jwt}`;
    }

  } catch (e) {
    console.error(e.message)
  }

  return '';
}


export { authHeader, authParams }
