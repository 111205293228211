export const app = {
  namespaced: true,
  state: {
    mail_domain: '',
    mails: [],
    rulesets: {},
    templates: [],
    current_mail: {},
    current_rulesets: {},
    current_ruleset: {},
    current_template: {},
    session_id: ''
  },
  getters: {},
  mutations: {
    updateSendAddress(state, send_address) {
      state.current_mail.send_address = send_address
    },
    updateSendName(state, send_name) {
      state.current_mail.send_name = send_name
    }
  },
  actions: {}
};
