<template>
  <div id="app">
    <mainHeader />
    <SessionTimer />
    <div :class="{ loading: on_load, invisible: load_done }">
      <div class="spinner">
        <div class="bounce1" />
        <div class="bounce2" />
        <div class="bounce3" />
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
import MainHeader from "./components/MainHeader"
import SessionTimer from "@/components/SessionTimer"
import axios from "axios";
import { API_URL } from "./api_url"

export default {
  name: 'App',

  components: {
    MainHeader,
    SessionTimer
  },
  data() {
    return {
      on_load: true,
      load_done: false,
    };
  },
  mounted() {
    this.on_load = true;
    this.load_done = false;

    if (!this.$store.state.auth.sessionId) {
      this.jwtLogin()
    } else {
      this.get_templates()
    }
  },
  methods: {
    async jwtLogin() {
      this.$store.dispatch("auth/login").then(
        () => { this.get_templates() },
        (err) => { }
      );
    },
    get_templates() {
      axios.get(`https://${API_URL}/v1/templates`, { headers: { 'Authorization': this.$store.state.auth.sessionId } })
        .then(response => {
          this.$store.state.app.templates = [...response.data].slice(0, 500);
          this.on_load = false;
          this.load_done = true;
        })
        .catch(err => {
          this.jwtLogin()
        });
    },
  }
}
</script>
