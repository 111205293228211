import store from "../store";

class SweetAlertService {
    error(error) {
        console.error('error', error);

        return {
            icon: 'error',
            title: error.response.data?.status || error.response.status,
            text: (error.response.data?.message || error.response.statusText),
            confirmButtonColor: 'green',
        }
    }

    warning(warningMessage) {
        return {
            icon: 'warning',
            text: warningMessage,
            confirmButtonColor: 'green',
        }
    }

    success(successMessage) {
        return {
            icon: 'success',
            text: successMessage,
            confirmButtonColor: 'green',
        }
    }

    confirm(title, confirmText = 'OK') {
        return {
            title: title,
            confirmButtonText: confirmText,
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            confirmButtonColor: 'green',
        }
    }

    input(title, preConfirmFunc = null, confirmText = 'OK', placeholder= 'Enter name here', defaultValue = '') {
        return {
            title: title,
            html: `<input type="text" id="name" class="swal2-input" value="${defaultValue}" placeholder="${placeholder}" />`,
            confirmButtonText: confirmText,
            focusConfirm: false,
            preConfirm: preConfirmFunc,
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            confirmButtonColor: 'green',
        }
    }
}
export default new SweetAlertService();
