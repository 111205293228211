import 'sweetalert2/dist/sweetalert2.min.css';

import { createApp } from 'vue'
import App from './App.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import router from './router'
import store from './store'
import "./assets/css/style.css"

import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';

const app = createApp(App)

app.use(router)
app.use(store)
app.use(VueSweetalert2)
app.use(PrimeVue)
app.use(ConfirmationService)

app.mount('#app')
