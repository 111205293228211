import axios from 'axios';
import { API_URL } from '../api_url';
import { authParams } from "@/services/auth-header";

class AuthService {
    auth() {
        return axios
            .get(`https://${API_URL}/v1/user/login${authParams()}`)
            .then(response => {
                return response.data;
            });
    }
}
export default new AuthService();
