<template>
    <div v-if="false" class="timer"><strong>SessionTimeout:</strong> {{ formatSessionTimeout }}</div>
</template>

<script>
import SweetAlertService from "@/services/sweetalert";

export default {
  name: "SessionTimer",
  data() {
    return {
      timerCount: this.$store.state.auth.sessionTimeout - Math.floor(Date.now() / 1000)
    }
  },
  created() {
    this.countDownTimer()
  },
  methods: {
    countDownTimer() {
      if (this.timerCount > 0) {
        setTimeout(() => {
          this.timerCount -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        if (this.$store.state.auth.sessionTimeout) {
          this.logout()
        }
      }
    },
    logout() {
      this.$store.dispatch("auth/logout").then(
          () => {
            console.log('logout');
            this.$swal(SweetAlertService.warning('Session expired. Please reload the window.'))
          },
          (err) => {
            this.$swal(SweetAlertService.error(err))
          }
      );
    }
  },
  computed: {
    formatSessionTimeout() {
      return new Date(this.timerCount * 1000).toISOString().substr(14, 5)
    }
  }
}
</script>

<style lang="scss" scoped>
.timer {
  position:  fixed;
  top: 0;
  right: 50px;
  font-size: 14px;
  margin: 10px
}
</style>
